.content {
  display: flex;
  flex-direction: row;
  flex: 1;

  .inline {
    flex-direction: row;
    display: flex;
    align-items: baseline;
    margin-right: 20px;

    .label {
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      color: var(--heading-color);
      font-weight: 500;

      .colon {
        padding: 0 8px 0 2px;
      }
    }

    input {
      flex-shrink: 0;
    }
  }
}
