.treeArea {
  padding: 10px 6px;
  .searchArea {
    padding: 0 4px;
    margin-bottom: 20px;
  }
  .treeBox {
    overflow: auto;
  }
  :global {
    .ant-tree-switcher-noop {
      width: 14px;
    }
    .ant-tree-list-holder-inner {
      padding-bottom: 20px;
    }
  }
}

.txt {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 8em;
}
