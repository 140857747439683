@mixin custom-style {
  font-size: 14px;
  font-family: 'PingFangSC-Regular', 'PingFang SC';
  font-weight: 400;
}

.functionSet {
  position: relative;
  display: flex;
  justify-content: center;
  height: 832px;
  width: 100%;

  .contentBg {
    position: absolute;
    width: 395px;
    height: 832px;
    background-image: url('../../Images/steps2Cover@2x.png');
    background-size: 395px 832px;
    background-repeat: no-repeat;

    .menuTitle {
      position: absolute;
      top: 35px;
      left: 50%;
      transform: translateX(-50%);
      @include custom-style;
      font-weight: 700;
      font-size: 20px;
    }

    .cardMenuTitle {
      top: 54px;
    }

    // 内容区域
    .wrapper {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 68px;
      width: 100%;
      height: 690px;

      .content {
        position: absolute;
        width: 375px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: #c1c1c1; // 滑块颜色
          border-radius: 5px; // 滑块圆角
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px; // 轨道圆角
        }

        .menuHomePage {
          position: absolute;
          top: 240px;
          left: 50%;
          transform: translateX(-50%);

          .text {
            display: flex;
            align-items: center;
            justify-content: center;
            @include custom-style;
            font-size: 14px;
            color: #1b1b1b;
            margin-bottom: 16px;
          }

          .removeTool {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 128px;
            height: 32px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            cursor: pointer;
          }

          .addTool {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 114px;
            height: 32px;
            background: #ffffff;
            border-radius: 2px;
            cursor: pointer;
          }
        }

        .addTools {
          margin: 0 auto;
          margin-top: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 114px;
          height: 32px;
          border-radius: 2px;
          cursor: pointer;

          // .plusIcon {
          //   font-size: 12px;
          // }

          // .addToolsText {
          //   margin-left: 10px;
          //   @include custom-style;
          //   font-style: 14px;
          // }
        }
      }
    }

    .cardMenuWrapper {
      height: 666px;
    }

    // 导航区域
    .navBar {
      position: absolute;
      bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 56px;

      .navBarArea {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #fff;
        width: 375px;
        height: 100%;
        padding: 0 8px;
        border-top: 1px solid #f6f6f6;
        overflow: hidden;

        .navBarTitle {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          position: relative;
          width: 84px;
          height: 44px;
          display: inline-flex;
          border-radius: 8px;
          cursor: pointer;
          overflow: hidden;

          &:hover {
            background-color: #e9f7f4;

            .navText {
              color: #0bb798;
            }
            .closeBlock {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .icon {
              color: #0bb798;
            }
          }

          .icon {
            font-size: 17px;
          }

          .navText {
            @include custom-style;
            font-size: 10px;
          }
        }

        .closeBlock {
          position: absolute;
          font-size: 8px;
          display: none;
          z-index: 999;
          right: 0;
          top: 0;
          width: 16px;
          height: 16px;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 0px 0px 0px 6px;
        }
      }
    }
  }

  .cardHomePageBg {
    background-image: url(../../Images/cardHomeBg.png);
    background-size: 395px 832px;
  }

  .config {
    position: absolute;
    right: 0;
    width: calc((100% - 395px) / 2);
    height: 100%;

    .configArea {
      position: absolute;
      display: flex;
      flex-direction: column;
      margin-top: 14px;
      margin-left: 14px;
      .switch {
        width: 44px;
        height: 22px;
        margin-bottom: 32px;
      }

      .card {
        display: flex;
        flex-direction: column;

        span {
          margin-bottom: 8px;
          @include custom-style;
        }
      }

      .tools {
        display: flex;
        flex-direction: column;

        span {
          margin-bottom: 8px;
          @include custom-style;
        }
      }

      .menus {
        display: flex;
        flex-direction: column;

        .switch {
          width: 44px;
          height: 22px;
          margin-bottom: 32px;
        }

        .menuText {
          margin-bottom: 8px;
          @include custom-style;
        }

        .menuSelect {
          width: 127px;
          height: 32px;
        }
      }
    }
  }

  .dndArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 10px 20px 10px;

    .group {
      width: 100%;
      height: auto;
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      .groupItemTitle {
        margin-left: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);
        line-height: 22px;
        cursor: pointer;
      }

      .itemList {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
          position: relative;
          width: 160px;
          height: 60px;
          background: #ffffff;
          box-shadow: 0px 5px 40px 0px rgba(0, 0, 0, 0.09);
          border-radius: 8px;
          backdrop-filter: blur(0px);
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
.itemBoxHover {
  &:hover {
    .closeStyle {
      display: flex;
    }
  }
}

.closeStyle {
  position: absolute;
  font-size: 8px;
  align-items: center;
  justify-content: center;
  display: none;
  cursor: pointer;

  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0px 0px 0px 6px;
}
.closeIcon {
  color: #fff;
}

.itemInfo {
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}

.menusForm {
  padding: 20px 75px 20px 75px;
}

.itemIcon {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-size: 44px 44px;
}
.icon_box {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(7, 1fr);
  .icon_box_item {
    width: 48px;
    height: 48px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    &.active {
      border-color: #ffd100;
    }
  }
}

.active_menu {
  color: #0bb798;
  background-color: #e9f7f4;
}

.active_navText {
  color: #0bb798;
}
