$prefixCls: open-platform-prompt;

.#{$prefixCls} {
  &-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
  }

  &-icon {
    color: var(--primary-color);
    margin-right: 5px;
  }

  &-title {
    color: var(--text-color-secondary);
  }
}
