.tabs {
  :global {
    .ant-tabs-nav {
      &::before {
        content: none;
      }
    }
  }
}

.wrapper {
  min-height: calc(100vh - 110px) !important;
  .row {
    padding: 20px 30px;
  }
}
