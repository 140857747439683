.modal {
  .tips {
    display: flex;
    justify-content: flex-end;
  }
}

.version {
  width: 66px;
  height: 24px;
  background: #e7e7e7;
  border-radius: 16px;
  text-align: center;
}
