$primary-color: var(--primary-color);

.operationArea {
  margin-bottom: 20px;
  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}
.container {
  background-color: transparent !important;
  padding: 0 !important;

  .description {
    padding: 30px 60px 30px 60px;
    background-color: white;
    margin-bottom: 10px;
    :global {
      .ant-descriptions-item-container {
        padding: 20px;
      }
    }
  }

  .table {
    position: relative;
    background-color: white;
    padding: 10px;
    .backBtn {
      color: #000;
      position: absolute;
      left: 20px;
      bottom: 10px;
    }
  }
}

.chooseBtn {
  border: 1px dashed $primary-color;
  color: $primary-color;
}
