.container {
  padding-left: 30px;
  padding-right: 30px;
  .label {
    height: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .functionFormLabelContainer {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.65);
    .tree {
      width: 100%;
    }
  }
}
