.container {
  min-height: calc(100vh - 145px);
  // padding-bottom: 40px;

  .functionFormLabelContainer {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.65);
    .tree {
      width: 100%;
    }
  }

  .buttonWrapper {
    margin-top: 30px;
    padding-top: 15px;
    position: sticky;
    bottom: 0;
    background: #ffffff;
    width: 100%;
  }
}
