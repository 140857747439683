$primary-color: var(--primary-color);
$component-background: var(--component-background);
.row {
  display: flex;
  flex-wrap: wrap;
  .col {
    height: 32px;
    margin: 10px 35px 10px 0;
    background: $component-background;
    flex-shrink: 0;
    flex-basis: 260px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;
    &.checked {
      background: $primary-color;
      color: #1b1b1b;
    }
  }
}
