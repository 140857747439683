@import '~github-markdown-css';

$headerHeight: 50px;

.container {
  width: 100%;
  height: calc(100vh - #{$headerHeight});
  padding: 14px;
  display: flex;
  flex-flow: row nowrap;

  .aside {
    width: 280px;
    height: 100%;
    background: #ffffff;
    border-radius: 2px;

    .list {
      overflow: auto;
      list-style: none;
      margin: 10px 0 0 0;
      padding: 0 0 0 10px;

      > li {
        width: calc(100% - 10px);
        height: 32px;
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff;
        margin-bottom: 7px;
        cursor: pointer;
        padding: 7px 0 7px 16px;
        line-height: 1.143;
        border-radius: 4px;

        &:hover {
          color: var(--primary-color);
        }

        &.isActive {
          background: var(--primary-color);
          color: #ffffff;
        }
      }
    }

    :global {
      .ant-tabs-tab {
        width: 140px;
        margin: 0;
        box-sizing: border-box;
        text-align: center;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .main {
    flex: 1;
    padding: 12px 12px 20px 12px;
    margin-left: 8px;
    background: #ffffff;
    border-radius: 2px;
    overflow: auto;

    .base-path-wrapper {
      font-size: 16px;
      line-height: 24px;
      height: 24px;
      margin-bottom: 10px;
    }

    .summary-method {
      display: inline-block;
      min-width: 80px;
      font-size: 14px;
      font-weight: 700;
      padding: 6px 15px;
      text-align: center;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.4);
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
      font-family: sans-serif;
      color: #fff;
      margin-right: 10px;
    }

    .block-section {
      .block-section-header {
        font-size: 12px;
        font-weight: 500;
        color: rgba(var(--base-text-color), 0.85);
        display: flex;
        align-items: center;
        padding: 8px 10px;
        min-height: 50px;
        background: hsla(0, 0%, 100%, 0.8);
        border-bottom: 1px solid rgba(59, 65, 81, 0.2);
      }
    }

    .block-description-wrapper {
      font-size: 14px;
      padding: 15px 10px;
      font-family: sans-serif;
      color: #3b4151;
      border-bottom: 1px solid rgba(59, 65, 81, 0.2);
      p {
        margin: 0;
      }
    }
  }
}
