.wrapper {
  min-height: calc(100vh - 112px) !important;
  margin-bottom: 10px !important;

  .deleteBtn {
    margin-bottom: 15px;
  }
}

.rangePicker {
  width: 100%;
}
