.operationArea {
  margin-bottom: 20px;
  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}
.tabs {
  :global {
    .ant-tabs-nav {
      &::before {
        content: none;
      }
    }
  }
}
.detail {
  :global {
    .ant-descriptions-row > th {
      padding-bottom: 5px;
    }
    .ant-descriptions-row > td {
      padding-bottom: 30px;
    }
  }
}

.wrapper {
  min-height: calc(100vh - 110px) !important;
  .col {
    margin-bottom: 35px;
  }
}
