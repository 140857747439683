.top {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  padding-bottom: 5px;
}
.icon {
  padding-left: 448px;
}
