.tabs {
  height: 48px;
  margin: 0 10px 16px;
  :global {
    .ant-tabs {
      .ant-tabs-nav {
        margin-bottom: 0;
        .ant-tabs-tab {
          min-width: 120px;
          //max-width: 180px;
          //margin: 0;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: bold;
          display: flex;
          justify-content: center;
          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              text-shadow: none;
            }
          }
        }
        .ant-tabs-ink-bar {
          min-width: 120px;
          max-width: 180px;
          bottom: 0;
          top: 0;
        }
      }
      .ant-tabs-extra-content {
      }
    }
  }
  border-bottom: 1px solid rgba(255,255,255,0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tab_box {
    flex: 1;
    overflow: hidden;
  }
}
