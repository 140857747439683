.disablePoint {
  width: 5px;
  height: 5px;
  background: #ff4d4f;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}
.enablePoint {
  width: 5px;
  height: 5px;
  background: #1ac671;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}
