.container {
  background: #f0f2f5;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  overflow: hidden;
}

.breadcrumb {
  background-color: white;
  padding: 7px 30px;
}

.form {
  background-color: white;
  padding: 20px;
  margin: 10px;
  .version {
    width: 66px;
    height: 24px;
    background: #e7e7e7;
    border-radius: 16px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 24px;
    font-weight: 400;
    text-align: center;
  }
}

.body {
  display: flex;
  flex: 1;
  height: calc(100% - 50px - 218px - 36px);
  margin: 0 10px 10px 10px;
}

.attributeList {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  background-color: white;
  width: 230px;
  height: 100%;
  padding-bottom: 20px;
  margin-right: 10px;
  .dashed {
    margin: 10px 5px;
    border-bottom: 1px dashed #d9d9d9;
  }
  .title {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    padding-left: 10px;
    margin-bottom: 14px;
  }
  .info {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
  }
  .item {
    color: rgba(0, 0, 0, 0.65);
    padding: 5px 27px;
    cursor: grab;
  }
  .itemDisabled {
    color: rgba(0, 0, 0, 0.3);
    cursor: not-allowed;
  }
}

.attributeCanvas {
  position: relative;
  background-color: white;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: 0 20px;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  .buttons {
    :global {
      button {
        padding: 4px 2px;
        font-size: 12px;
      }
    }
  }
  .footer {
    position: sticky;
    left: 28px;
    padding-left: 28px;
    bottom: 0px;
    background-color: white;
    height: 60px;
    width: 100%;
  }
}

.dragArea {
  height: 143px;
  background: rgba(0, 0, 0, 0.04);
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin: 30px 0;
}
