.wrapper {
  padding: 0 !important;
  margin-bottom: 10px;

  .footer {
    position: sticky;
    padding-left: 28px;
    bottom: 0;
    background-color: white;
    height: 60px;
    width: 100%;
  }
}

.info {
  padding: 20px 0;

  .remark {
    display: flex;
    align-items: center;

    .input {
      width: 160px;
      color: #333;
    }

    .action {
      width: 100px;
      text-align: center;
    }
  }
}

.form {
  padding: 50px 60px;
}

.dropdownMenu {
  :global {
    a {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

.inline {
  display: flex;

  .tip {
    color: #333;
    padding: 0 10px;
  }
}

.hint {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.35);
}

.version {
  width: 66px;
  height: 24px;
  background: #e7e7e7;
  border-radius: 16px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  font-weight: 400;
  text-align: center;
}
