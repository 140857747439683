$primaryColor: var(--primary-color);

.App {
  height: 100%;

  .footer {
    width: 100%;
    margin: 30px 0;
    padding: 15px 40px 15px 70px;
    position: sticky;
    bottom: 0;
    background: var(--component-background);
    z-index: 3;
  }
}

.sticky-footer {
  position: sticky;
  left: 0;
  padding-left: 28px;
  bottom: 0px;
  background-color: white;
  height: 60px;
  width: 100%;
  z-index: 3;
}

.sticky-footer-left {
  position: sticky;
  left: 0;
  bottom: 0px;
  background-color: white;
  height: 60px;
  width: 100%;
}

// table 操作栏按钮 后续统一使用该class
.action-buttons {
  .ant-btn-link {
    padding: 0;
    line-height: 30px;
    height: 30px;
    font-size: 12px;
  }
  .ant-btn {
    font-size: 12px;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 2px;
  }
}

// 临时解决列表操作栏的样式，后续使用上面的class
.ant-table-cell {
  .ant-space {
    .ant-btn-link {
      padding: 0;
      line-height: 30px;
      height: 30px;
      font-size: 12px;
    }
    .ant-btn {
      font-size: 12px;
    }
    .ant-btn > .anticon + span,
    .ant-btn > span + .anticon {
      margin-left: 2px;
    }
  }
  .ant-btn {
    font-size: 12px;
  }
  .ant-badge {
    .ant-badge-status-text {
      font-size: 12px;
    }
  }
}

.ant-form-item__longLabel {
  label {
    font-size: 12px;
  }
}

.detail-col-gap {
  margin-bottom: 35px;
}

.ant-input-number-disabled {
  border-color: rgba(118, 118, 118, 0.3);
}

.ant-input-number-disabled .ant-input-number-input,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper-disabled {
  color: #00000040;
  background: #f5f5f5;
}

.loading-modal {
  .loading-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loading-modal-icon {
      font-size: 50px;
      color: $primaryColor;
    }
    .loading-modal-txt {
      padding-bottom: 10px;
    }
  }
  .ant-modal-confirm-btns {
    display: none;
  }
}

.ant-btn-link.ant-btn-disabled {
  border-color: transparent;
  background-color: transparent;

  &:hover {
    border-color: transparent;
    background-color: transparent;
  }
}
