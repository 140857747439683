.wrapper {
  margin: -20px;
  
  .content {
    margin-top: 90px;
    padding: 0 70px;

    .detail {
      padding-bottom: 100px;

      .display {
        margin-bottom: -20px;
        padding-left: 28px;

        .field {
          display: flex;
          flex-direction: row;
          margin-top: 23px;

          .label {
            color: #333;
            font-weight: 400;
          }

          .value {
            margin-left: 10px;
            color: #333;
            font-weight: 400;
          }
        }

        .value {
        }
      }
    }
  }

  .footer {
    position: sticky;
    left: 28px;
    padding-left: 28px;
    bottom: 0px;
    background-color: white;
    height: 60px;
    width: 100%;
  }
}
