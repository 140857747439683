.container {
  width: 100%;
  height: 100%;
  background: fixed #1a293d url('../../assets/image/bg.jpg') no-repeat;
  background-size: cover;
  overflow: hidden;
  font-size: 1rem;
}

.content {
  min-width: 1280px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.display {
  width: 100%;
  height: 100%;
  margin: 13% 10% 8% 7%;
  .displayVideo {
    width: 80%;
    height: 100%;
  }
  .displayImage {
    width: 80%;
    height: 100%;
    background: url('../../assets/image/dshx.png') 0 center no-repeat;
    background-size: contain;
  }
}

.loginHeader {
  position: absolute;
  top: 60px;
  left: 50px;

  .headerTitle {
    height: 23px;
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    line-height: 20px;
  }
  .headerSubTitle {
    margin-top: 12px;
    font-size: 12px;
    font-weight: 200;
    color: rgba(199, 199, 199, 1);
    line-height: 22px;
  }
}

.cardForm {
  width: 400px;
  min-height: 420px;
  background-color: rgba(11, 48, 73, 0.8);
  padding: 30px;
  margin-right: 250px;
  border-radius: 8px;
  box-shadow: 0px 0px 45px 4px rgba(19, 26, 53, 0.43);

  .alertMsg {
    font-size: 15px;
    line-height: 30px;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    border-radius: 4px;
  }
  .formGroup {
    margin-top: 40px;
    margin-bottom: 0;
    :global {
      .ant-input-affix-wrapper {
        background-color: #252a3a;
        border: 1px solid rgba(255, 255, 255, 0.15);
        padding: 9px 11px;
        &:hover {
          border: 1px solid #1890ff;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }

        > input.ant-input {
          background-color: transparent;
          color: #fff;
          font-size: 12px;

          &::placeholder {
            color: rgba(255, 255, 255, 0.4);
          }
        }

        input:-internal-autofill-selected {
          background-clip: text;
          -webkit-text-fill-color: #fff;
        }

        .anticon {
          color: rgba(255, 255, 255, 0.65);
          font-size: 18px;
        }
      }
    }
  }
  .rememberName {
    margin-top: 1rem;

    :global {
      .ant-checkbox .ant-checkbox-inner {
        background-color: transparent;
        border-color: #ffd100;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ffd100;
      }
      .ant-checkbox-wrapper {
        color: #fff;
        font-size: 12px;
      }
      .ant-checkbox {
        align-self: baseline;
      }
    }
  }

  .loginBtn {
    width: 100%;
    height: 45px;
    background: #ffd100;
    border: none;
    transition: 0.5s all;
    color: #fff;
    font-size: 12px;
    &:hover {
      background: #ffdf29 !important;
      color: #fff !important;
    }
  }
}

.cardFormTitle {
  text-align: center;
  .mainTitle {
    font-size: 24px;
    font-weight: 400;
    color: rgba(246, 246, 246, 1);
    line-height: 22px;
  }
  .subTitle {
    font-size: 14px;
    font-weight: 400;
    color: rgba(246, 246, 246, 1);
    margin-top: 20px;
    line-height: 22px;
  }
}

.bottomText {
  position: absolute;
  bottom: 3%;
  text-align: center;
  width: 100%;
  margin: 0;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: inherit;
  }
}

/* 屏幕小于1000px */
@media screen and (max-width: 1000px) {
  .container {
    background-image: none;
  }
  .content {
    min-width: 100%;
  }
  .loginHeader {
    display: none;
  }

  .display {
    margin: 0;
    background-color: #021622;
  }

  .loginForm {
    position: absolute;
    width: 100%;
  }

  .cardForm {
    width: 100%;
    margin: 0;
    height: 100vh;
    min-height: 100%;
    padding-top: 80px;
    background-color: rgba(2, 22, 34, 0.8);
    border-radius: 0;

    .cardFormBody {
      margin-top: 120px;
    }

    .formGroup {
      margin-top: 12px;
      :global {
        .ant-input-affix-wrapper {
          background-color: rgba(37, 42, 58, 0.7) !important;
        }
      }
    }
  }
}

/* 屏幕大于2560px */
@media screen and (min-width: 2560px) {
  html,
  body {
    font-size: 20px !important;
  }

  .loginHeader .headerTitle,
  .cardFormTitle .mainTitle {
    font-size: 1.5rem !important;
    height: 2rem !important;
    line-height: 1.5rem !important;
  }

  .loginHeader .headerSubTitle {
    font-size: 0.9rem;
  }

  .alertMsg {
    padding: 15px 25px;
    :global {
      .ant-alert-close-icon {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
  .headerSubTitle,
  .cardForm .rememberName {
    :global {
      .ant-checkbox .ant-checkbox-inner,
      .ant-checkbox-wrapper {
        font-size: 0.9rem;
      }
    }
  }

  .cardFormTitle .subTitle {
    font-size: 1rem !important;
    line-height: 2rem !important;
    margin-top: 0.6rem !important;
  }

  .cardForm {
    width: 25rem !important;
    min-height: 26rem !important;
    padding: 1.6rem !important;

    .formGroup {
      :global {
        .ant-input-affix-wrapper {
          border-radius: 0.25rem;
          > input.ant-input {
            height: 2rem;
            font-size: 1.1rem;
          }

          .anticon:not(.ant-input-password-icon) {
            font-size: 27px;
          }

          .ant-input-prefix {
            margin-inline-end: 16px;
          }
        }
      }
    }
  }

  .cardForm .loginBtn {
    border-radius: 0.25rem;
    height: 3rem !important;
    font-size: 1.2rem;
  }
}

/* 屏幕大于3840px */
@media screen and (min-width: 3840px) {
  html,
  body {
    font-size: 30px !important;
  }

  .cardForm .formGroup {
    :global {
      .ant-input-affix-wrapper {
        border-radius: 4px;
        > input.ant-input {
          height: 2rem;
          font-size: 1.1rem;
        }

        .anticon:not(.ant-input-password-icon) {
          font-size: 40px;
        }

        .ant-input-prefix {
          margin-inline-end: 30px;
        }
      }
    }
  }

  .cardForm .rememberName {
    :global {
      .ant-checkbox .ant-checkbox-inner,
      .ant-checkbox-wrapper {
        font-size: 0.9rem;
      }
      .ant-checkbox .ant-checkbox-inner {
        width: 0.9rem;
        height: 0.9rem;
      }
      .ant-checkbox .ant-checkbox-inner::after,
      .ant-checkbox .ant-checkbox-inner:after {
        transform: rotate(45deg) scale(1.6) translate(-50%, -50%);
        inset-inline-start: 25%;
      }
    }
  }

  input[type='checkbox'] {
    transform: scale(1.8) !important;
    margin-right: 0.3rem !important;
  }
}
