.name {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC, sans-serif;
  font-weight: 1000;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
}

.companyName {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC, sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

:global {
  .ant-card-actions {
    background: #f7f7f7;
  }
}
