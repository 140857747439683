.steps {
  padding: 50px 150px 50px 150px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 24px;
}

.bgContainer {
  width: 90%;
  margin: 0 auto;
  padding: 40px 0 40px 0;
  height: 100%;
  background-color: #f6f6f6;

  .contentStyle {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .button {
    display: flex;
    justify-content: center;
  }
}

.menusForm {
  width: 100%;
  height: 100%;
  padding: 30px 100px;
}
