.entrance {
  position: relative;
  width: 375px;
  height: 812px;
  background-image: url('../../Images/cover@2x.png');
  background-size: 375px 812px;
  background-repeat: no-repeat;
  background-color: #ccc;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 144px;
    left: 30px;
    width: 60px;
    height: 60px;
    border-radius: 12px;
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-color: #fff;
    cursor: pointer;
    z-index: 99;

    .textStyle {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fff;
    }

    .cover {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 12px;
      background: rgba(0, 0, 0, 0.35);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(0px);
      z-index: 999;

      .dashed {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 47px;
        height: 52px;
        border: 1px dashed #919191;
      }

      :global {
        .ant-upload-select {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 47px;
          height: 52px;
          background-color: transparent;
          border: none;
          color: #fff;
          z-index: 999;
        }
      }
    }
  }

  .textArea {
    position: absolute;
    top: 213px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 335px;
    height: 180px;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #ffffff;

    .text {
      width: 100%;
      height: 100%;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 33px;
      background-color: transparent;
    }
  }

  .bg {
    position: absolute;
    top: 614px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    background: #f3f3f3;
    border-radius: 6px;
    opacity: 0.84;

    .bgUpload {
      :global {
        .ant-upload-select {
          width: 60px;
          height: 60px;
          border-radius: 6px;
          border: none;
          background-color: transparent;
        }
      }
    }
  }

  .bgChange {
    position: absolute;
    top: 685px;
    left: 50%;
    transform: translateX(-50%);
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
  }
}
