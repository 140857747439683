.operationArea {
  margin-bottom: 20px;
  width: 500px;
  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}

.container {
  min-height: calc(100vh - 145px);
  padding-bottom: 40px;

  .example {
    padding-top: 50px;
    align-items: flex-start;
    display: flex;

    :global {
      span {
        color: #aaa;
      }

      img {
        width: 255px;
        height: 68px;
        margin-left: 20px;
      }
    }
  }

  .buttonWrapper {
    margin-top: 30px;
    padding-top: 15px;
    position: sticky;
    bottom: 0;
    background: #ffffff;
    width: 100%;
  }
}
.lineBox {
  display: flex;
  align-items: center;
  .openCounts {
    color: red;
    padding: 0 3px;
  }
  .assignDetail {
    padding: 0 5px;
    cursor: pointer;
    color: var(--primary-color);
  }
}
.formSubTitle {
  padding: 5px 0;
  color: #000;
}
.mg_t {
  margin-top: 20px;
}
.successTip {
  color: #52c41a;
}
.errTip {
  color: #ff4d4f;
}
.warningTip {
  color: #faad14;
}
