.textWrap {
  word-wrap: break-word;
  max-width: '98%';
  word-break: break-all;
  white-space: pre-wrap;
}

.tagDisplay {
  width: 100%;
  text-wrap: wrap;

  :global {
    .ant-tag {
      margin: 0 4px 4px 0;
    }
  }
}
