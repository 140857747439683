.wrapper {
  padding: 0 !important;
  margin-bottom: 10px;

  :global {
    .ant-row.ant-form-item {
      //align-items: baseline;
    }
  }

  .footer {
    position: sticky;
    padding-left: 28px;
    bottom: 0;
    background-color: white;
    height: 60px;
    width: 100%;
  }
}

.info {
  padding: 20px 0;

  .remark {
    display: flex;
    word-wrap: break-word;
    word-break: break-all;

    .input {
      width: 160px;
      color: #333;
    }

    .action {
      height: 32px;
      padding-left: 10px;

      &.baseline {
        align-items: baseline;
      }
    }
  }
}

.form {
  padding: 50px 60px;
}

.dropdownMenu {
  :global {
    a {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

.inline {
  display: flex;

  .tip {
    color: #333;
    padding: 0 10px;
  }
}

.hint {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.35);
}

.version {
  width: 66px;
  height: 24px;
  background: #e7e7e7;
  border-radius: 16px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
  font-weight: 400;
  text-align: center;
}

.remarkItem {
  align-items: flex-start !important;
}
