.wrapper {
  min-height: calc(100vh - 115px) !important;
  display: flex;
  flex-direction: column;
  padding: 0 50px 50px !important;

  .wrapperInner {
    //  width: 1000px;
    //  display: flex;
    //  flex-direction: column;
    //  align-items: center;
  }
}

.stepsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  .steps {
    width: 375px;
    margin: 32px 0;
  }
}

.ctrlWrap {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.simpleModelTable {
  .row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    .rowItem {
      width: 137px;
    }

    .minusIcon {
      cursor: pointer;
    }

    .andText {
      margin-left: 10px;
      transform: translateY(-85%);
    }
  }
}

.mb0 {
  margin-bottom: 0 !important;
}
