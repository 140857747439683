@import 'vars';

$tree-font-size: 14px;

.sw-tree-bracket-wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid transparent;
  &:hover {
    color: $sw-primary-color;
    background: #efefef;
    border: 1px solid #ccc;
  }
}

.sw-tree {
  font-family: $sw-font-mono;
  font-size: $tree-font-size;
  .sw-tree-content {
    padding-left: 1em;
    border-left: 1px dotted #ccc;
  }

  .sw-tree-bracket-left,
  .sw-tree-bracket-right {
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 3px;
    padding: 0 20px 2px 0;
    &:hover {
      color: $sw-primary-color;
      background: #efefef;
      border: 1px solid #ccc;
    }
  }

  .sw-datatype {
    &-null {
      color: $sw-soft-red;
    }
    &-bool,
    &-numb,
    &-inte,
    &-number,
    &-boolean {
      color: $sw-info;
    }
    &-stri,
    &-string {
      color: darken($sw-green, 5%);
    }
    &-enum {
      color: $sw-orange;
    }
  }

  .sw-content-block {
    .sw-content-key {
      white-space: nowrap;
    }
    .sw-content {
      white-space: nowrap;
    }
    .sw-content.sw-datatype-enum {
      white-space: normal;
      padding-top: 0;
      min-width: 150px;
    }

    display: flex;
    align-items: stretch;
    width: 100%;
    &:hover {
      background: #efefef;
    }
  }

  .sw-descr {
    min-width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    color: $sw-gray-text;
    text-align: right;
    &.sw-descr-collapsed {
      white-space: nowrap;
    }
    &.sw-descr-expanded {
      white-space: normal;
    }
  }
  .sw-show-descr .sw-descr {
    display: block;
  }
  .sw-hide-descr .sw-descr {
    display: none;
  }
  .sw-descr-expanded + .sw-descr-expander {
    border-left: 2px solid #ccc;
  }
  .sw-descr-expander {
    padding: 0 5px;
    margin-left: 5px;
    cursor: pointer;
    color: orange;
    &:hover {
      color: orangered;
    }
  }

  .item-required::after {
    display: inline-block;
    content: '*';
    color: #ff1a3b;
    width: 10px;
    height: 18px;
  }
}
