$component-background: var(--component-background);
.container {
  // min-height: calc(100vh - 145px);
  // padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
  // position: relative;

  .example {
    padding-top: 50px;
    align-items: flex-start;
    display: flex;

    :global {
      span {
        color: #aaa;
      }

      img {
        width: 255px;
        height: 68px;
        margin-left: 20px;
      }
    }
  }

  .buttonWrapper {
    margin-top: 30px;
    padding-top: 15px;
    position: sticky;
    bottom: 0;
    background: $component-background;
    width: 100%;
  }
}
