.container {
  padding-left: 30px;
  padding-right: 30px;
  .tip {
    color: var(--mx-text-secondary-color);
  }
  .channelInfo {
    color: var(--mx-text-base-color);
    margin-bottom: 24px;
  }
  .channelLabel {
    color: var(--mx-text-secondary-color);
    margin-right: 8px;
  }
  .channelTable {
    margin-top: 8px;
    :global {
      .ant-empty-description {
        color: var(--mx-text-secondary-color);
        .ant-btn {
          padding: 0 0 0 5px;
        }
      }
    }
  }
  :global {
    .anticon-exclamation-circle {
      color: var(--mx-primary-color);
      margin-right: 2px;
    }
  }
}
