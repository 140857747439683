.form {
  position: relative;
  margin: 0 30px;

  .status {
    position: absolute;
    right: 0;
    top: 0;
  }
}
