.operationArea {
  margin-bottom: 20px;

  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}

.formItemLable {
  min-width: 80px;
}

.container {
  background-color: #ffffff;
  min-height: calc(100vh - 150px) !important;
  //padding: 0 !important;

  .description {
    padding: 30px 60px 30px 60px;
    margin-bottom: 10px;

    :global {
      .ant-descriptions-item-container {
        padding: 20px;
      }
    }
  }

  .link {
    margin-left: 15px;
  }

  .button {
    margin-left: 60px;
  }
}
