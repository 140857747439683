$component-background: var(--component-background);
$primary-color: var(--primary-color);
.container {
  min-height: calc(100vh - 145px);
  padding-bottom: 40px;
  .buttonWrapper {
    margin-top: 30px;
    padding-top: 15px;
    position: sticky;
    bottom: 0;
    background: $component-background;
    width: 100%;
  }
}

.functionFormLabelContainer {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.65);
}

.warnIcon {
  margin-right: 5px;
  color: $primary-color;
}
