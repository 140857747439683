.outer {
  min-height: calc(100vh - 110px)!important;
}
.steps_box {
  width: 90%;
  margin: 0 auto;
}
.content_box {
  padding: 20px 0 10px;
}
.form_content {
  width: 70%;
  margin: 0 auto;
  max-width: 500px;
}
.main_color {
  color: var(--primary-color);
  cursor: pointer;
}
.input_box {
  width: 300px;
  padding: 10px 0 20px;
}
.search_icon {
  color: rgba(0,0,0,.45);
  cursor: pointer;
}
.tempate_box {
  display: flex;
  gap: 12px;
  
  .left {
    flex: 1;
    border: 1px solid #D9D9D9;
  }
  .right {
    flex: 1;
    // height: 180px;
    border: 1px solid #D9D9D9;
    overflow-y: auto;
    padding: 10px;
    .label,.value {
      font-size: 12px;
    }
  }
}
.gap {
  height: 10px;
  background-color: #F0F2F5;
  margin: 0 -20px;
}
.buttons {
  padding: 20px 0;
}
.modal_box {
  width: 80%;
  margin: 0 auto;
  span {
    color: rgba(0,0,0,0.45);
    font-size: 12px;
  }
}

.chart_box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 420px;
  border: 1px solid rgba(24,24,24,0.15);
}
.form_area {
  padding: 20px;
}