.title {
  padding: 0 20px;
  margin-bottom: 10px;

  .icon {
    margin-right: 8px;
  }
}

.container {
  position: relative;
}

.content {
  padding: 20px 20px 40px;
  max-height: 300px;
  overflow-y: auto;
}

.alert {
  width: 512px;
  position: absolute;
  left: 20px;
  bottom: 0;
}
