.wrapper {
  padding: 50px 60px !important;
  margin-bottom: 10px !important;
  min-height: calc(100vh - 112px) !important;

  .datePicker {
    width: 100%;
  }
}

.subFormWrapper {
  width: 100%;
  display: flex;

  .subForm {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px;

    :global {
      .ant-form-item {
        margin-bottom: 10px;
      }
    }

    .menuIconDrag {
      touch-action: none;
      cursor: move;
      font-size: 20px;
    }
  }

  .removeBtn {
    margin-left: 15px;
  }
}

.addCategoryBtn {
  width: 150px;
  height: 32px;
}

.addRowBtn {
  margin-top: 12px;
  width: 100%;
  height: 32px;
  background: #ffffff;
}
