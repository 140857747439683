$prefixCls: open-platform-log-detail;
$origin-bg: #fafafa;
$modified-bg: var(--item-active-bg);
$modified-color: #d62500;

.#{$prefixCls} {
  &-header {
    height: 38px;
    background: $origin-bg;
    border-radius: 4px 4px 0 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65);

    &-modified {
      background: $modified-bg;
    }
  }

  &-body {
    min-height: 38px;
    padding: 9px 0;
    line-height: 20px;
    border-radius: 4px 4px 0 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    overflow-wrap: break-word;
    word-break: break-all;

    &-modified {
      color: $modified-color;
    }
  }
}
