$primaryColor: var(--primary-color);

.btn {
  width: 120px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed $primaryColor;
  color: $primaryColor;
  cursor: pointer;
  flex-direction: row;

  svg {
    font-size: 12px;
    position: absolute;
    left: 5px;
    margin-top: -6px;
  }

  span {
    cursor: pointer;
  }
}

.value {
  .tag {
    margin-bottom: 5px;
  }
}

.ouModal {
  min-height: 550px;

  .space {
    column-gap: 20px !important;
    row-gap: 0 !important;

    .display {
      display: flex;
      vertical-align: top;
      margin-bottom: 24px;
      justify-content: center;
      align-items: center;

      label {
        color: #333;
      }

      .disabled {
        color: #333;

        svg {
          display: none;
        }
      }
    }
  }

  .table_text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
    margin-top: 40px;

    .tips {
      padding-bottom: 5px;

      .red {
        color: #d62500;
        padding: 0 5px;
        font-weight: 500;
      }

      svg {
        margin-left: 15px;
        color: #fee17a;
      }

      .grey {
        margin-left: 5px;
        color: rgba(0, 0, 0, 0.35);
        font-size: 12px;
      }
    }
  }
}
