$component-background: var(--component-background);
.container {
  // min-height: calc(100vh - 145px);
  // padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;
  // position: relative;

  .statistic {
    margin-bottom: 30px;
  }

  .buttonWrapper {
    margin-top: 30px;
    padding-top: 15px;
    position: sticky;
    bottom: 0;
    background: $component-background;
    width: 100%;
  }

  .listItem {
    .img {
      width: 120px;
      height: 60px;
      object-fit: contain;
    }
    .listTitle {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 13px;
    }
    .listContent {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  :global {
    .ant-list-item {
      padding: 40px 0 40px 0;
    }
  }
}
