.content {
  margin-bottom: 10px !important;
  min-height: calc(100vh - 112px) !important;
}

.checkboxes {
  display: flex;
  height: 200px;
  width: 500px;

  flex-wrap: wrap;
  .checkbox {
    display: inline-block;
    height: 30px;
    width: 80px;
  }
}
.subtitle {
  flex: 1;
  margin-left: 16px;
}

.divider {
  height: 1px;
  background-color: #e9e9e9;
  margin: 12px 0;
}

.dynamicForm {
  margin-bottom: 12px;
  :global {
    .ant-form-item {
      margin-bottom: 12px;
    }
  }
}
