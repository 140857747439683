.titleBox {
  padding: 30px 30px 0;
  .leftLine {
    margin-right: 4px;
    display: inline-block;
    vertical-align: text-top;
    width: 4px;
    height: 16px;
    background: var(--primary-color);
  }
  .rightText {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
}
.irradiationVal {
  margin-top: 8px;
  width: 300px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  line-height: 20px;
}

.footer {
  margin-top: 30px;
}
