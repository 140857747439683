.btnBlank {
  margin-bottom: 10px;
}

.uploadSty {
  :global {
    .mx-rc-upload-tip {
      margin-top: 6px;
      color: var(--mx-text-desc-color);
    }
  }
}