$primary-color: var(--primary-color);
$link-color: var(--link-color);
$padding: 5px;

.container {
  // min-height: calc(100vh - 145px);
  // padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;

  .cardWrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    .col {
      margin-bottom: 30px;
      .card {
        background: rgba(0, 0, 0, 0.02);
        box-shadow: 2px 2px 5px 0px rgba(209, 209, 209, 0.5);
        border-radius: 2px;
        border: 1px solid rgba(139, 139, 139, 0.46);
        :global {
          .ant-card-head {
            border-bottom: none;
            .ant-card-head-title {
              padding-top: $padding;
              padding-bottom: $padding;
            }
            .ant-card-extra {
              padding-top: $padding;
              padding-bottom: $padding;
              .ant-btn {
                padding: 0;
              }
            }
          }
          .ant-card-body {
            padding-top: $padding;
            padding-bottom: $padding;
          }
        }
        .tag {
          background: rgba(255, 209, 0, 0.05);
          border-radius: 1px;
          border: 1px solid $primary-color;
          color: $link-color;
        }
      }
    }
  }

  .table {
    border: 1px solid #e8e8e8;
  }
}

.modalLog {
  .header {
    border-radius: 4px 4px 0px 0px;
    &.old {
      background: #fafafa;
    }
    &.new {
      background: var(--item-active-bg);
    }
    .title {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 0;
      &.change {
        color: #d62500;
      }
    }
  }
}
