.siteTreeSearchValue {
  color: #00adff;
}
.paddingContent {
  padding: 5px 40px 5px 40px;
  .treeArea {
    padding-top: 20px;
    width: "100%";
  }
}
.footer {
  display: flex;
  justify-content: space-between;
}
