.operationArea {
  margin-bottom: 20px;
  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}

.tabs {
  :global {
    .ant-tabs-nav {
      &::before {
        content: none;
      }
    }
  }
}
