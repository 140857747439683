.outer {
  height: calc(100vh - 110px) !important;
  padding: 0 !important;
}

.container {
  display: flex;
  gap: 10px;
  height: 100%;

  .card_box {
    padding: 20px 10px;
    flex: 1;

    .filter {
      border-bottom: 1px solid #dadada;
    }

    overflow-y: auto;
  }

  .tree_box {
    padding: 20px 10px;
    width: 230px;
    border-right: 1px solid #dadada;
    overflow-y: auto;
  }
}

.parent_node {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  .op {
    display: none;
    padding: 0 5px;

    &:hover {
      color: var(--primary-color);
    }
  }

  &:hover {
    .op {
      display: block;
    }
  }
}

.child_node {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 130px;

  .label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .op {
    display: none;
    padding: 0 5px;

    .edit,
    .remove {
      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &:hover {
    .op {
      display: flex;
    }
  }
}

.mask_selected {
  border: 1px solid #fab000;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);

  &::before {
    content: '✓';
    width: 31px;
    height: 22px;
    background: #ffd100;
    border-radius: 8px 0px 0px 0px;
    color: #fff;
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 99;
    align-items: center;
    justify-content: center;
  }
}

.mask_hidden {
  cursor: pointer;
}

.mask_outer {
  position: relative;

  .mask {
    position: absolute;
    overflow: hidden;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // transition: all .2s;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .icons {
      display: flex;
      align-items: center;
      gap: 15px;
      justify-content: space-between;
    }

    .icon_block {
      padding: 5px 10px;
      display: flex;
      cursor: pointer;
      text-align: center;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(2px);
      border-radius: 2px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 12px;

      &:hover {
        background: rgba(255, 209, 0, 0.5);
      }
    }
  }

  &:not(.mask_hidden):hover {
    .mask {
      display: flex;
    }
  }

  .inner_content {
    .no_cover {
      width: 100%;

      img {
        width: 100%;
      }

      p {
        text-align: center;
        padding-top: 10px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  :global {
    .ant-tag {
      margin-right: 0;
    }

    .ant-card-head {
      padding: 5px 8px;
      min-height: auto;
    }

    .ant-card-body {
      padding-bottom: 10px;
    }
  }
}

.list {
  min-height: calc(100vh - 320px);
  display: flex;
  align-items: center;
  justify-content: center;
}
