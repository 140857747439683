.container {
  background: #f0f2f5;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 50px);
}

.breadcrumb {
  background-color: white;
  padding: 7px 30px;
}

.form {
  background-color: white;
  padding: 20px;
  margin: 10px;
  .version {
    width: 66px;
    height: 24px;
    background: #e7e7e7;
    border-radius: 16px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 24px;
    font-weight: 400;
    text-align: center;
  }
}

.body {
  flex: 1;
  margin: 0 10px 10px 10px;
  background-color: white;
  padding-bottom: 60px;
}

.list {
  padding: 0 20px;
  & > div:last-child {
    border-bottom: none !important;
  }
}

.footer {
  position: sticky;
  left: 28px;
  padding-left: 28px;
  bottom: 0px;
  background-color: white;
  height: 60px;
  width: 100%;
}
