$primary-color: var(--primary-color);
$link-color: var(--link-color);

.content {
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  .titleWrapper {
    .title {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
    .tag {
      background: rgba(255, 209, 0, 0.05);
      border-radius: 1px;
      border: 1px solid $primary-color;
      color: $link-color;
    }
  }
}

.container {
  .tabs {
    overflow: visible;
    :global {
      .ant-tabs-nav {
        padding-left: 30px;
      }
    }
  }
}
