.roleWrapper {
  height: calc(100vh - 112px);
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  background: #ffffff;

  .formWrapper {
    width: 100%;
  }
}

.permissionsWrapper {
  // height: calc(100vh - 112px);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;

  .desc {
    flex-shrink: 0;
    flex-grow: 0;
    width: 1000px;
    height: 43px;
    background-color: #f8f8f8;
    align-items: center;
    margin-bottom: 30px;
  }

  .treeContainer {
    width: 1000px;
    height: 540px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;

    > h3 {
      width: 100%;
      padding: 10px 0 20px 10px;
    }
  }

  .treeWrapper {
    width: 900px;
  }
}

.buttonContainer {
  width: 1000px;
  position: sticky;
  background: #ffffff;
  height: 60px;
  bottom: 0;
  margin-top: 20px;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}
