.image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    width: 53px;
    // height: 50px;
    object-fit: cover;
  }
}
