.outer {
  // min-height: calc(100vh - 110px)!important;
  padding: 10px 20px 20px !important;
}

.filter {
  padding-top: 10px;
}

.page_box {
  // padding: 0 10px;
}

.mask_outer {
  position: relative;

  .mask {
    position: absolute;
    overflow: hidden;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // transition: all .2s;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 99;

    .icons {
      display: grid;
      width: 100%;
      margin: 0 auto;
      grid-template-columns: repeat(auto-fill, 20%);
      justify-content: center;
      grid-gap: 10px;
    }

    .icon_block {
      padding: 5px 10px;
      // width: 45px;
      // margin: 0 auto;
      display: flex;
      text-align: center;
      cursor: pointer;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(2px);
      border-radius: 2px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 12px;

      &:hover {
        background: rgba(255, 209, 0, 0.5);
      }
    }
  }

  &:hover {
    .mask {
      display: flex;
    }
  }

  .inner_content {
    .no_cover {
      width: 100%;

      img {
        width: 100%;
      }

      p {
        text-align: center;
        padding-top: 10px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  :global {
    .ant-tag {
      margin-right: 0;
    }

    .ant-card-head {
      padding: 5px 8px;
      min-height: auto;
    }

    .ant-card-body {
      padding-bottom: 10px;
    }
  }
}

.head_title {
  display: flex;
  align-items: center;
}

.sub_title,
.head_suffix,
.sub_suffix {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  font-weight: 400;
}

.sub_title,
.head_title_left {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.head_suffix {
  display: flex;
  justify-content: flex-end;
}

.sub_suffix {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;

  .icon {
    width: 12px;

    img {
      width: 100%;
    }
  }
}

.list {
  min-height: calc(100vh - 300px);
  display: flex;
  align-items: center;
  justify-content: center;
}
