$prefixCls: mgmt-steps;

.#{$prefixCls}-wrapper {
  width: 100%;
  height: 160px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  .ant-steps-item-title::after {
    background: rgba(0, 0, 0, 0.25) !important;
  }
}
