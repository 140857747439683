$primary-color: var(--primary-color);

.icon {
  color: $primary-color;
}

.operateContainer {
  margin-top: 20px;
}
.contactAdmin {
  cursor: inherit;
  //text-decoration: underline;
}

.verification_box {
  display: flex;
  align-items: center;
  gap: 8px;
} 
