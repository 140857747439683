$height: calc(100vh - 344px);

.page {
}

.breadcrumb {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px 30px;
}

.pointTypeRadio {
  margin: 10px;
}

.filter {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;

  .formItem {
    margin-right: 20px;
    white-space: nowrap;
  }

  .label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    &::after {
      content: ':';
      position: relative;
      top: -0.5px;
      margin: 0 8px 0 2px;
    }
  }
}

.container {
  margin: 0 10px;
}

.sider {
  margin-right: 10px;
  min-height: $height;
}

.content {
  background-color: #fff;
}

.toolBar {
  padding: 0 30px;
  margin-bottom: 38px;
}

.unit {
  padding: 0 16px;
  margin-bottom: 10px;
  text-align: right;
}

.main {
  padding: 0 30px;
  min-height: $height;
}

.toolBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.emptyWrap {
  min-height: $height;
  display: flex;
  align-items: center;
  justify-content: center;
}
