.operationArea {
  margin-bottom: 20px;
  :global {
    .ant-btn > .anticon + span {
      margin-left: 2px;
    }
  }
}
.filterNav {
  :global {
    .ant-tabs-nav {
      margin: 0;
    }
    .ant-tabs-nav::before {
      border: none;
    }
    .ant-tabs-nav .ant-tabs-ink-bar {
      top: 0;
    }
  }
}
