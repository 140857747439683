//Font
$sw-font-family: 'Metropolis';
$sw-font-mono: 'roboto-mono', 'Monaco', 'Menlo', 'Consolas';

//Colors
$sw-primary-color: #ff791a;

$sw-dark-bg1: #333;
$sw-dark-bg2: #515151;
$sw-dark-bg3: #65656a;

$sw-blue-text: #0079b8;
$sw-dark-text: #565656;
$sw-gray-text: #999;
$sw-light-text: #c9c9c9;
$sw-extra-light-text: #fafafa;

$sw-light-bg1: #fafafa;
$sw-light-bg2: whitesmoke;
$sw-light-bg3: #f2f2f2;
$sw-white: #fff;

$sw-green: #99cc00;
$sw-red: #cc0000;
$sw-soft-red: #ed4337;
$sw-orange: #ff9900;
$sw-info: #47afe8;
$sw-unknown: #cccccc;

$sw-icon-critical: $sw-red;
$sw-icon-error: #ff0000;
$sw-icon-warn: $sw-orange;
$sw-icon-warn-minor: #ffcc00;
$sw-icon-warn-low: #efe47e;
$sw-icon-good: $sw-green;
$sw-icon-info: $sw-info;
$sw-icon-unknown: $sw-unknown;

//Borders
$sw-border-color: #ccc;
$sw-light-border-color: #eee;
$sw-border-radius: 2px;
