.operationArea {
  margin-bottom: 20px;
}

.toolModal {
  :global {
    .ant-modal-content {
      padding-bottom: 0px;

      .ant-modal-footer {
        margin: 12px -24px 0 -24px;
      }
    }
  }
  .footer {
    padding: 12px 16px;
    border-top: 1px solid #e9e9e9;
    width: 100%;
    justify-content: end;
  }

  .steps {
    width: 350px;
    margin: 30px auto;

    :global {
      .ant-steps-item-finish {
        .ant-steps-item-icon {
          background: transparent !important;
          border: 1px solid #ffd100 !important;
        }
        .ant-steps-item-title {
          color: rgba(0, 0, 0, 0.45) !important;
          &::after {
            background-color: #e9e9e9 !important;
          }
        }
      }
    }
  }

  .toolForm {
    padding: 0 56px;

    :global {
      .ant-form-item-label {
        width: 80px;
      }
    }
  }

  .permissionManage {
    display: flex;
    flex-direction: row;
    height: 548px;
    padding: 0 36px;

    .permissionList {
      width: 260px;
      height: 100%;
      padding: 8px 4px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.15);

      .permissionTitle {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
      }

      .permissionTree {
        padding-left: 12px;
        height: 500px;
        overflow: auto;
        :global {
          .ant-tree-switcher {
            line-height: 32px;
            width: auto;
          }

          .ant-tree-indent-unit {
            width: 16px;
          }

          .ant-tree-node-content-wrapper {
            line-height: 32px;
            display: inline-block;

            &:hover {
              background: #fff6cd;
            }
          }
        }

        .treeNode {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .treeLabel {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;
          }

          .ar {
            display: none;

            .add,
            .remove {
              margin-left: 6px;
              &:hover {
                color: var(--primary-color);
              }
            }
          }

          &:hover {
            .ar {
              display: flex;
            }
          }
        }
      }
    }

    .rightConfig {
      flex: 1;
      margin-left: 48px;
    }
  }
}

.content {
  padding: 20px !important;
  min-height: calc(100vh - 110px) !important;
}
