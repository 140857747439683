.wrapper {
  width: 100%;
  height: 100%;
  padding: 0 40px;

  .rangePicker {
    width: 100%;
  }
}

.deleteConfirmModal {
  display: flex;

  .icon {
    margin-right: 16px;
    color: var(--warning-color);
    font-size: 22px;
  }

  .sendValidCodeBtn {
    color: var(--primary-color);
    cursor: pointer;
  }

  .loading, .countDown {
    color: var(--disabled-color);
  }
}
