.form {
  display: flex;
  height: 80px;
  align-items: center;
  padding-top: 10px;

  .input {
    width: 345px;
    margin-right: 20px;
  }

  .plusIcon {
    cursor: pointer;
  }
}
