$primary-color: var(--primary-color);

.personalFormContainer {
  background-color: white;
  padding: 20px 40px;

  .formTitle {
    position: relative;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    font-size: 18px;
    padding-left: 10px;

    .titleBorder {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      width: 4px;
      height: 20px;
      background-color: $primary-color;
    }
  }

  :global {
    Form {
      width: 50%;
      margin: 30px auto;
    }
    Button {
      margin-right: 10px;
    }
  }
}
