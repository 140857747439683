.outer {
  min-height: calc(100vh - 110px)!important;
}
.steps_box {
  width: 90%;
  margin: 0 auto;
}
.form_content {
  width: 60%;
  margin: 0 auto;
  padding: 20px 0 10px;
  max-width: 400px;
}
.main_color {
  color: var(--primary-color);
  cursor: pointer;
}
.error {
  color: red;
  padding: 0 20px;
}
