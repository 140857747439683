.tableForm {
  :global {
    .ant-table-thead,
    .ant-table-tbody {
      .ant-table-cell {
        padding: 10px 10px 16px;
        .ant-form-item-with-help .ant-form-item-explain {
          position: absolute;
          left: 0;
          bottom: -22px;
          .ant-form-item-explain-error {
            font-size: 12px;
          }
        }
      }
      .ant-form-item {
        margin-bottom: 0px;
      }
    }
  }
}
