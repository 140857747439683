.wrapper {
  :global {
    .ant-tabs-tab {
      width: 90px;
      display: flex;
      justify-content: center;
    }
  }
}

.itemWrapper {
  width: 100%;
  height: 128px;
  align-items: center;
  justify-content: flex-start;
}

.itemContent {
  margin-left: 20px;
  flex: 1;

  .detail {
    color: var(--text-color-secondary);
    margin-top: 10px;
  }
}
