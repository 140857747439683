$primary-color: var(--primary-color);
$component-background: var(--component-background);

.container {
  padding-left: 30px;
  padding-right: 30px;

  .icon {
    color: $primary-color;
  }

  .example {
    padding-top: 50px;
    align-items: flex-start;
    display: flex;

    :global {
      span {
        color: #aaa;
      }

      img {
        width: 255px;
        height: 68px;
        margin-left: 20px;
      }
    }
  }

  .imgUpload {
    width: 110px;
    :global {
      .ant-upload.ant-upload-select-picture-card {
        border: none;
      }
    }
  }
  .uploadTip {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 10px;
    width: calc(100% - 112px);
    color: #aaa;
  }
  .tips {
    margin-top: 20px;
  }

  .homePic {
    width: 104px;
    height: 104px;
  }

  .defaultPic {
    width: 260px;
    margin-bottom: 30px;
  }

  .pText {
    width: 104px;
    height: 104px;

    p {
      border: 1px dashed rgba(0, 0, 0, 0.15);
      background-color: rgb(250 250 250);
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 24%;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC, sans-serif;
      font-weight: 400;
      padding-top: 58px;
      cursor: pointer;
    }

    // :hover {
    //   border: 1px dashed $primary-color;
    // }
  }

  .imgBorder {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mask {
    position: absolute;
    // top: 0px;
    // left: 0px;
    width: 86px;
    height: 86px;
    background: black;
    opacity: 0.5;
    z-index: 99;

    .btn {
      position: absolute;
      left: 32%;
      top: 32%;

      :hover {
        color: rgb(255, 255, 255);
      }
    }
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homeImg {
    width: 86px;
    height: 86px;
    position: relative;
  }

  .buttonWrapper {
    margin-top: 30px;
    padding-top: 15px;
    position: sticky;
    bottom: 0;
    background: $component-background;
    width: 100%;
    z-index: 100;
  }
}

.previewModal {
  width: 1040px;
  height: 585px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .previewImg {
    width: 1040px;
    // height: 240px;
    // object-fit: scale-down;
  }

  .topMenu {
    width: 1040px;
  }
}
