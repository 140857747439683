.content {
  padding: 0px !important;
  :global {
    .row1 {
      background-color: rgba(255, 248, 217, 1);
    }
    .row2 {
      background-color: rgba(255, 252, 242, 1);
    }
  }
}

.tabs {
  :global {
    .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
      bottom: inherit;
      top: 0;
    }
    .ant-tabs-nav {
      &::before {
        content: none;
      }
      margin-bottom: 0px;
      padding: 0 20px;
    }
  }
}

.sticky-footer {
  position: sticky;
  left: 0;
  padding-left: 28px;
  bottom: 0px;
  background-color: white;
  height: 60px;
  width: 100%;
}
