$primary-color: var(--primary-color);

.icon {
  color: $primary-color;
}

.operateContainer {
  margin-top: 20px;
}
.contactAdmin {
  cursor: inherit;
  //text-decoration: underline;
  &:hover {
    //text-decoration: underline;
  }
}
