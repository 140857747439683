.preRelease {
  span {
    display: block;
    display: flex;
    margin-top: 24px;
    margin-bottom: 76px;
    justify-content: center;
    align-items: center;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
  }

  .QrCode {
    margin-top: 128px;
    width: 320px;
    height: 320px;
    background-color: #fff;
    background-size: 320px 320px;
    background-repeat: no-repeat;
  }
}
