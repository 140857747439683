@mixin mouseCoverBg {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
}

@mixin cardTitle($fontSize) {
  margin-top: 4px;
  margin-left: 8px;
  font-size: $fontSize;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
}

.cardWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 6px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1; // 滑块颜色
    border-radius: 5px; // 滑块圆角
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px; // 轨道圆角
  }

  .cardArea {
    position: relative;
    overflow: hidden;

    .small {
      position: relative;
      background-image: url('../../../Images/card-small@2x.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 9px;
      overflow: hidden;

      .cardTitle {
        @include cardTitle(12px);
      }

      &:hover {
        &::after {
          @include mouseCoverBg;
        }

        cursor: grab;

        .deleteBtn {
          display: flex;
        }
      }
    }

    .middle {
      position: relative;
      background-image: url('../../../Images/card-middle@2x.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 9px;
      overflow: hidden;

      .cardTitle {
        @include cardTitle(14px);
      }

      &:hover {
        &::after {
          @include mouseCoverBg;
        }
        cursor: grab;
        .deleteBtn {
          display: flex;
        }
      }
    }

    .large {
      position: relative;
      background-image: url('../../../Images/card-big@2x.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 9px;
      overflow: hidden;

      .cardTitle {
        @include cardTitle(16px);
      }

      &:hover {
        &::after {
          @include mouseCoverBg;
        }
        cursor: grab;
        .deleteBtn {
          display: flex;
        }
      }
    }

    .deleteBtn {
      position: absolute;
      display: none;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      width: 16px;
      height: 16px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 0px 0px 0px 6px;
      cursor: pointer;
      z-index: 9999;

      .closeIcon {
        width: 8px;
        height: 8px;
        color: #fff;
      }
    }
  }

  // 有数据时
  .addCardBtnWithData {
    position: absolute;
    margin-top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 116px;
    height: 32px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
  }

  .addCardBtn {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 116px;
    height: 32px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
  }
}

.sizeTag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 22px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.sizeTagHover {
  background: #fffbe6 !important;
  color: #faad14 !important;
  border: 1px solid #ffe58f !important;
}

.draggableCancel {
  cursor: pointer;
}
