$primary-color: var(--primary-color);
$background-color: #1b1b1b;
$menu-background-color: #1f1f1f;
$aside-menu-sub-color: var(--aside-menu-sub-color);
$aside-background-color: var(--aside-background-color);
$component-background: var(--component-background);
$header-active-color: var(--header-active-color);
$header-color: var(--header-color);

$prefixCls: central-menu;

.#{$prefixCls}-layout-wrap {
  height: 100%;

  .#{$prefixCls}-header {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 10px 5px;
    align-items: center;
    color: rgba($header-color, 0.65);
    background: $background-color;
    height: 50px;
    line-height: 50px;

    .#{$prefixCls}-link {
      color: rgba($color: #000000, $alpha: 0.65);
      text-decoration: none;
      &:hover {
        color: rgba($color: #000000, $alpha: 0.65);
        text-decoration: none;
      }
    }

    .ant-avatar {
      background-color: $primary-color;
    }

    .#{$prefixCls}-inline-collapsed {
      padding-left: 8px;
      padding-right: 0;
    }

    .#{$prefixCls}-logo-wrapper {
      padding-left: 15px;
      display: flex;
      color: var(--primary-color);
      width: 245px;
      align-items: center;

      .#{$prefixCls}-logo-title {
        color: $primary-color;
        width: 90px;
        font-size: 10px;
        display: flex;
        justify-content: flex-start;
        padding:0 8px;
        align-items: center;
        white-space: nowrap;
        pointer-events: none;
        user-select: none;
      }
    }

    .#{$prefixCls}-menu {
      flex: 1;
      min-width: 0;
      line-height: 44px;
      background: $menu-background-color;
      border-bottom: none;

      .ant-menu.ant-menu-dark {
        background: $background-color;
        display: flex;
        align-items: center;
      }
      .ant-menu-overflow {
        .ant-menu-title-content {
          display: flex;
          .menuContent {
            width: 5px;
            background-color: $background-color;
            pointer-events: none;
          }
          .menuLink {
            padding: 0 20px;
            border-radius: 3px;
          }
        }
        &:nth-child(n) {
          &::before {
            content: '';
            width: 15px;
          }
        }
        .ant-menu-item {
          // margin: 0 15px;
          padding: 0;
          background: $background-color;
          color: rgba($header-color, 0.65);
          line-height: 30px;
          border-radius: 3px;
          a {
            color: rgba($header-color, 0.65) !important;
            // color: #000000;
          }
          &:hover {
            color: rgba($header-color, 0.8);
            a {
              color: rgba($header-color, 0.8) !important;
            }
          }
        }
        .ant-menu-item-selected {
          .ant-menu-title-content {
            .menuLink {
              background-color: $primary-color !important;
              color: $header-active-color !important;
            }
          }
        }
      }
    }
  }

  .ant-layout-has-sider {
    height: calc(100%);
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }

  .#{$prefixCls}-sider {
    background: $aside-background-color;
    .ant-layout-sider-children {
      overflow: hidden auto;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: hsla(0, 0%, 100%, 0.2);
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 5px hsl(0deg 0% 100% / 5%);
      }
      &::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, 0.15);
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 5px rgb(37 37 37 / 5%);
      }
    }
    .ant-menu-submenu:hover {
      .ant-menu-submenu-arrow {
        color: rgb($header-color);
      }
    }
    .ant-menu-item {
      color: rgb($header-color);
      a {
        color: rgb($header-color) !important;
      }
      &::after {
        border-right: 0;
      }
      width: calc(100%);
    }
    .ant-menu-item-selected {
      background-color: $primary-color !important;
      color: $header-active-color !important;
      a {
        color: $header-active-color !important;
      }
    }
    .ant-menu {
      background: transparent;
      color: rgba($header-color, 0.85);
      .ant-menu-submenu-title {
        color: rgba($header-color, 0.85) !important;
      }
      .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          color: rgb($header-color) !important;
        }
      }

      .ant-menu-submenu-arrow,
      .ant-menu-submenu-expand-icon {
        color: rgb($header-color);
      }
      .ant-menu-sub {
        background: $aside-menu-sub-color;
      }
    }
  }

  .#{$prefixCls}-content {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  ##{$prefixCls}-sider-wrapper {
    display: none;
  }

  .#{$prefixCls}-header-popup {
    .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark {
      background-color: var(--component-background);
      .ant-menu.ant-menu-sub {
        background-color: var(--component-background);
      }
    }
    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark > .ant-menu .ant-menu-item,
    .ant-menu-dark .ant-menu-submenu-title,
    .ant-menu-dark > .ant-menu .ant-menu-submenu-title {
      color: #000000;
      &:hover {
        color: #000000;
      }
    }
  }
}
