.modal {
  .tips {
    svg {
      color: #fee17a;
    }

    .grey {
      color: rgba(0, 0, 0, 0.35);
      font-size: 12px;
      margin-left: 5px;
    }
  }

  .space {
    column-gap: 20px !important;
    row-gap: 0 !important;

    .display {
      display: flex;
      vertical-align: top;
      margin-bottom: 24px;
      justify-content: center;
      align-items: center;

      label {
        color: #333;
      }

      .disabled {
        color: #333;

        svg {
          display: none;
        }
      }
    }
  }
}

.estunDetails {
  .action {
    line-height: 32px;
  }

  .version {
    width: 66px;
    height: 24px;
    background: #e7e7e7;
    border-radius: 16px;
    text-align: center;
  }

  .customModal {
    :global {
      .ant-modal-header {
        background: #fff !important;
      }
    }
  }
}

.mockingbirdForm {
  .action {
    line-height: 32px;
  }

  .version {
    width: 66px;
    height: 24px;
    background: #e7e7e7;
    border-radius: 16px;
    text-align: center;
  }

  .customModal {
    :global {
      .ant-modal-header {
        background: #fff !important;
      }
    }
  }

  :global {
    .ant-row.ant-form-item {
      align-items: unset;
    }
  }
}
